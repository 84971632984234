import React, { Suspense, useEffect } from "react";
import PropTypes from 'prop-types';
import AppRouter from "./routes/AppRouter";
import Loading from "./app/components/Loading";
import { useDispatch } from "react-redux";
import {getMyProfile} from "./app/redux/features/userSlice";

export const UserContext = React.createContext({ user: {} });

function App(props) {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getMyProfile());
  },[dispatch]);

  return (
    <Suspense fallback={<Loading />}>
      <AppRouter signOut={props.signOut} />
    </Suspense>
  );
}
App.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default App;
