/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const authorize = /* GraphQL */ `
  query Authorize($action: String!, $role: String!) {
    authorize(action: $action, role: $role) {
      error
      message
      object
      status
    }
  }
`;
export const getAbandonedCallRecords = /* GraphQL */ `
  query GetAbandonedCallRecords(
    $endDate: String!
    $limit: Int
    $nextToken: String
    $startDate: String!
    $workspace: String!
  ) {
    getAbandonedCallRecords(
      endDate: $endDate
      limit: $limit
      nextToken: $nextToken
      startDate: $startDate
      workspace: $workspace
    ) {
      items {
        answerDuration
        answeredAt
        direction
        duration
        endAt
        entry
        from
        fromDisplay
        handledBy
        hangupBy
        hangupReason
        id
        interaction
        interactionEntry {
          Interaction_Customer_Index
          Interaction_Handled_By_Index
          Interaction_Status_Index
          Interaction_Time_Index
          Interaction_Type_Time_Index
          Interaction_Workspace_Customer_Index
          Interaction_Workspace_Index
          channel
          createdAt
          customer
          direction
          disposition
          entry
          id
          key
          message
          otherData
          serverTimeStamp
          sessionId
          status
          tags
          type
          updatedAt
          updatedBy
          workspace
        }
        isAgentAnswered
        key
        mos
        processedAt
        recordingKey
        startedAt
        to
        toDisplay
        totalHoldTime
        totalLegCount
        totalTalkTime
        type
        waitDuration
        workspace
      }
      nextToken
    }
  }
`;
export const getActiveAbandonCallThresholds = /* GraphQL */ `
  query GetActiveAbandonCallThresholds(
    $limit: Int
    $nextToken: String
    $team: String!
    $workspace: String!
  ) {
    getActiveAbandonCallThresholds(
      limit: $limit
      nextToken: $nextToken
      team: $team
      workspace: $workspace
    ) {
      items {
        entry
        isActive
        key
        threshold
        timeSlot
      }
      nextToken
    }
  }
`;
export const getActiveFlow = /* GraphQL */ `
  query GetActiveFlow($flowId: String!, $workspace: String!) {
    getActiveFlow(flowId: $flowId, workspace: $workspace) {
      Flow_Start_Node
      Flow_Workspace_Index
      activeVersion
      createdAt
      createdBy
      description
      entry
      id
      key
      name
      type
      url
      version
    }
  }
`;
export const getAllFlows = /* GraphQL */ `
  query GetAllFlows($limit: Int, $nextToken: String, $workspace: String!) {
    getAllFlows(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
      items {
        Flow_Start_Node
        Flow_Workspace_Index
        activeVersion
        createdAt
        createdBy
        description
        entry
        id
        key
        name
        type
        url
        version
      }
      nextToken
    }
  }
`;
export const getCallRule = /* GraphQL */ `
  query GetCallRule($number: String!, $workspace: String!) {
    getCallRule(number: $number, workspace: $workspace) {
      Rule_Number_Index
      createdAt
      createdBy
      description
      direction
      endTimeIndex
      isActive
      name
      number
      offRuleAction
      offRuleActionData
      ruleAction
      ruleActionData
      startTimeIndex
      timeMode
      type
      workspace
    }
  }
`;
export const getCallRuleByNumber = /* GraphQL */ `
  query GetCallRuleByNumber($number: String!) {
    getCallRuleByNumber(number: $number) {
      items {
        Rule_Number_Index
        createdAt
        createdBy
        description
        direction
        endTimeIndex
        isActive
        name
        number
        offRuleAction
        offRuleActionData
        ruleAction
        ruleActionData
        startTimeIndex
        timeMode
        type
        workspace
      }
      nextToken
    }
  }
`;
export const getCdrEntries = /* GraphQL */ `
  query GetCdrEntries($limit: Int, $nextToken: String, $workspace: String!) {
    getCdrEntries(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
      items {
        answerDuration
        answeredAt
        direction
        duration
        endAt
        entry
        from
        fromDisplay
        handledBy
        hangupBy
        hangupReason
        id
        interaction
        interactionEntry {
          Interaction_Customer_Index
          Interaction_Handled_By_Index
          Interaction_Status_Index
          Interaction_Time_Index
          Interaction_Type_Time_Index
          Interaction_Workspace_Customer_Index
          Interaction_Workspace_Index
          channel
          createdAt
          customer
          direction
          disposition
          entry
          id
          key
          message
          otherData
          serverTimeStamp
          sessionId
          status
          tags
          type
          updatedAt
          updatedBy
          workspace
        }
        isAgentAnswered
        key
        mos
        processedAt
        recordingKey
        startedAt
        to
        toDisplay
        totalHoldTime
        totalLegCount
        totalTalkTime
        type
        waitDuration
        workspace
      }
      nextToken
    }
  }
`;
export const getCdrEntriesByCallDirection = /* GraphQL */ `
  query GetCdrEntriesByCallDirection(
    $direction: String!
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getCdrEntriesByCallDirection(
      direction: $direction
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        answerDuration
        answeredAt
        direction
        duration
        endAt
        entry
        from
        fromDisplay
        handledBy
        hangupBy
        hangupReason
        id
        interaction
        interactionEntry {
          Interaction_Customer_Index
          Interaction_Handled_By_Index
          Interaction_Status_Index
          Interaction_Time_Index
          Interaction_Type_Time_Index
          Interaction_Workspace_Customer_Index
          Interaction_Workspace_Index
          channel
          createdAt
          customer
          direction
          disposition
          entry
          id
          key
          message
          otherData
          serverTimeStamp
          sessionId
          status
          tags
          type
          updatedAt
          updatedBy
          workspace
        }
        isAgentAnswered
        key
        mos
        processedAt
        recordingKey
        startedAt
        to
        toDisplay
        totalHoldTime
        totalLegCount
        totalTalkTime
        type
        waitDuration
        workspace
      }
      nextToken
    }
  }
`;
export const getCdrEntriesByDate = /* GraphQL */ `
  query GetCdrEntriesByDate(
    $date: String!
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getCdrEntriesByDate(
      date: $date
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        answerDuration
        answeredAt
        direction
        duration
        endAt
        entry
        from
        fromDisplay
        handledBy
        hangupBy
        hangupReason
        id
        interaction
        interactionEntry {
          Interaction_Customer_Index
          Interaction_Handled_By_Index
          Interaction_Status_Index
          Interaction_Time_Index
          Interaction_Type_Time_Index
          Interaction_Workspace_Customer_Index
          Interaction_Workspace_Index
          channel
          createdAt
          customer
          direction
          disposition
          entry
          id
          key
          message
          otherData
          serverTimeStamp
          sessionId
          status
          tags
          type
          updatedAt
          updatedBy
          workspace
        }
        isAgentAnswered
        key
        mos
        processedAt
        recordingKey
        startedAt
        to
        toDisplay
        totalHoldTime
        totalLegCount
        totalTalkTime
        type
        waitDuration
        workspace
      }
      nextToken
    }
  }
`;
export const getCdrEntriesByFromNumber = /* GraphQL */ `
  query GetCdrEntriesByFromNumber(
    $limit: Int
    $nextToken: String
    $number: String!
    $workspace: String!
  ) {
    getCdrEntriesByFromNumber(
      limit: $limit
      nextToken: $nextToken
      number: $number
      workspace: $workspace
    ) {
      items {
        answerDuration
        answeredAt
        direction
        duration
        endAt
        entry
        from
        fromDisplay
        handledBy
        hangupBy
        hangupReason
        id
        interaction
        interactionEntry {
          Interaction_Customer_Index
          Interaction_Handled_By_Index
          Interaction_Status_Index
          Interaction_Time_Index
          Interaction_Type_Time_Index
          Interaction_Workspace_Customer_Index
          Interaction_Workspace_Index
          channel
          createdAt
          customer
          direction
          disposition
          entry
          id
          key
          message
          otherData
          serverTimeStamp
          sessionId
          status
          tags
          type
          updatedAt
          updatedBy
          workspace
        }
        isAgentAnswered
        key
        mos
        processedAt
        recordingKey
        startedAt
        to
        toDisplay
        totalHoldTime
        totalLegCount
        totalTalkTime
        type
        waitDuration
        workspace
      }
      nextToken
    }
  }
`;
export const getCdrEntriesByHandledByAgent = /* GraphQL */ `
  query GetCdrEntriesByHandledByAgent(
    $limit: Int
    $nextToken: String
    $user: String!
    $workspace: String!
  ) {
    getCdrEntriesByHandledByAgent(
      limit: $limit
      nextToken: $nextToken
      user: $user
      workspace: $workspace
    ) {
      items {
        answerDuration
        answeredAt
        direction
        duration
        endAt
        entry
        from
        fromDisplay
        handledBy
        hangupBy
        hangupReason
        id
        interaction
        interactionEntry {
          Interaction_Customer_Index
          Interaction_Handled_By_Index
          Interaction_Status_Index
          Interaction_Time_Index
          Interaction_Type_Time_Index
          Interaction_Workspace_Customer_Index
          Interaction_Workspace_Index
          channel
          createdAt
          customer
          direction
          disposition
          entry
          id
          key
          message
          otherData
          serverTimeStamp
          sessionId
          status
          tags
          type
          updatedAt
          updatedBy
          workspace
        }
        isAgentAnswered
        key
        mos
        processedAt
        recordingKey
        startedAt
        to
        toDisplay
        totalHoldTime
        totalLegCount
        totalTalkTime
        type
        waitDuration
        workspace
      }
      nextToken
    }
  }
`;
export const getCdrEntriesByToNumber = /* GraphQL */ `
  query GetCdrEntriesByToNumber(
    $limit: Int
    $nextToken: String
    $number: String!
    $workspace: String!
  ) {
    getCdrEntriesByToNumber(
      limit: $limit
      nextToken: $nextToken
      number: $number
      workspace: $workspace
    ) {
      items {
        answerDuration
        answeredAt
        direction
        duration
        endAt
        entry
        from
        fromDisplay
        handledBy
        hangupBy
        hangupReason
        id
        interaction
        interactionEntry {
          Interaction_Customer_Index
          Interaction_Handled_By_Index
          Interaction_Status_Index
          Interaction_Time_Index
          Interaction_Type_Time_Index
          Interaction_Workspace_Customer_Index
          Interaction_Workspace_Index
          channel
          createdAt
          customer
          direction
          disposition
          entry
          id
          key
          message
          otherData
          serverTimeStamp
          sessionId
          status
          tags
          type
          updatedAt
          updatedBy
          workspace
        }
        isAgentAnswered
        key
        mos
        processedAt
        recordingKey
        startedAt
        to
        toDisplay
        totalHoldTime
        totalLegCount
        totalTalkTime
        type
        waitDuration
        workspace
      }
      nextToken
    }
  }
`;
export const getCdrEntry = /* GraphQL */ `
  query GetCdrEntry($cdrEntryId: String!, $workspace: String!) {
    getCdrEntry(cdrEntryId: $cdrEntryId, workspace: $workspace) {
      answerDuration
      answeredAt
      direction
      duration
      endAt
      entry
      from
      fromDisplay
      handledBy
      hangupBy
      hangupReason
      id
      interaction
      interactionEntry {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      isAgentAnswered
      key
      mos
      processedAt
      recordingKey
      startedAt
      to
      toDisplay
      totalHoldTime
      totalLegCount
      totalTalkTime
      type
      waitDuration
      workspace
    }
  }
`;
export const getCdrLegs = /* GraphQL */ `
  query GetCdrLegs(
    $cdrEntryId: String!
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getCdrLegs(
      cdrEntryId: $cdrEntryId
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        answerDuration
        answeredAt
        callId
        direction
        duration
        endAt
        entry
        from
        fromDisplay
        hangupBy
        hangupReason
        holdTime
        id
        interaction
        key
        mos
        processedAt
        startedAt
        to
        toDisplay
        type
        waitDuration
        workspace
      }
      nextToken
    }
  }
`;
export const getCurrentNumbers = /* GraphQL */ `
  query GetCurrentNumbers(
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getCurrentNumbers(
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        city
        compliance
        country
        lata
        mms
        mmsRate
        monthlyRate
        number
        provider
        region
        restriction
        rule {
          Rule_Number_Index
          createdAt
          createdBy
          description
          direction
          endTimeIndex
          isActive
          name
          number
          offRuleAction
          offRuleActionData
          ruleAction
          ruleActionData
          startTimeIndex
          timeMode
          type
          workspace
        }
        setupRate
        sms
        smsRate
        status
        subType
        type
        url
        voice
        voiceRate
        workspace
      }
      nextToken
    }
  }
`;
export const getCustomerById = /* GraphQL */ `
  query GetCustomerById($id: String!, $workspace: String!) {
    getCustomerById(id: $id, workspace: $workspace) {
      Customer_User_Index
      Customer_Workspace_Index
      active
      avatar
      createdAt
      firstName
      gender
      id
      lastName
      locale
      name
      ssn
      tags
      title
      workspace
    }
  }
`;
export const getCustomerDetailsById = /* GraphQL */ `
  query GetCustomerDetailsById($id: String!, $workspace: String!) {
    getCustomerDetailsById(id: $id, workspace: $workspace) {
      addresses {
        city
        country
        number
        state
        street
        type
        zipCode
      }
      contacts {
        Customer_Contact_Index
        contact
        createdAt
        customer
        isPrimary
        isValid
        type
        workspace
      }
      info {
        Customer_User_Index
        Customer_Workspace_Index
        active
        avatar
        createdAt
        firstName
        gender
        id
        lastName
        locale
        name
        ssn
        tags
        title
        workspace
      }
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($limit: Int, $nextToken: String, $workspace: String!) {
    getCustomers(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
      items {
        Customer_User_Index
        Customer_Workspace_Index
        active
        avatar
        createdAt
        firstName
        gender
        id
        lastName
        locale
        name
        ssn
        tags
        title
        workspace
      }
      nextToken
    }
  }
`;
export const getCustomersDetailByContact = /* GraphQL */ `
  query GetCustomersDetailByContact($contact: String!, $workspace: String!) {
    getCustomersDetailByContact(contact: $contact, workspace: $workspace) {
      Customer_Contact_Index
      contact
      createdAt
      customer
      isPrimary
      isValid
      type
      workspace
    }
  }
`;
export const getFilesForType = /* GraphQL */ `
  query GetFilesForType(
    $category: String
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getFilesForType(
      category: $category
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        contentType
        createdAt
        display
        entry
        file
        key
        owner
        size
        type
        versionId
        workspace
      }
      nextToken
    }
  }
`;
export const getFlow = /* GraphQL */ `
  query GetFlow($flowId: String!, $version: String!, $workspace: String!) {
    getFlow(flowId: $flowId, version: $version, workspace: $workspace) {
      Flow_Start_Node
      Flow_Workspace_Index
      activeVersion
      createdAt
      createdBy
      description
      entry
      id
      key
      name
      type
      url
      version
    }
  }
`;
export const getFlows = /* GraphQL */ `
  query GetFlows(
    $flowId: String!
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getFlows(
      flowId: $flowId
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      flow {
        Flow_Start_Node
        Flow_Workspace_Index
        activeVersion
        createdAt
        createdBy
        description
        entry
        id
        key
        name
        type
        url
        version
      }
      nextToken
      versions {
        Flow_Start_Node
        createdAt
        createdBy
        entry
        key
        name
        url
        version
      }
    }
  }
`;
export const getInteraction = /* GraphQL */ `
  query GetInteraction($id: String!, $workspace: String!) {
    getInteraction(id: $id, workspace: $workspace) {
      Interaction_Customer_Index
      Interaction_Handled_By_Index
      Interaction_Status_Index
      Interaction_Time_Index
      Interaction_Type_Time_Index
      Interaction_Workspace_Customer_Index
      Interaction_Workspace_Index
      cdrLegs {
        duration
        from
        hangupReason
        legId
        to
      }
      channel
      createdAt
      customer
      direction
      disposition
      entry
      from {
        display
        id
        type
      }
      handledBy {
        handledAs
        handledAt
        id
      }
      id
      key
      lastHandledBy {
        handledAs
        handledAt
        id
      }
      message
      myCdrLeg {
        duration
        from
        hangupReason
        legId
        to
      }
      otherData
      serverTimeStamp
      sessionId
      status
      tags
      to {
        display
        id
        type
      }
      type
      updatedAt
      updatedBy
      workspace
    }
  }
`;
export const getInteractionDetails = /* GraphQL */ `
  query GetInteractionDetails($id: String!, $workspace: String!) {
    getInteractionDetails(id: $id, workspace: $workspace) {
      disposition {
        createdAt
        createdBy
        data
        disposition
        entry
        key
      }
      events {
        createdAt
        entry
        eventCode
        eventData
        eventDisplay
        eventName
        eventType
        id
        key
        sessionId
        timeStamp
      }
      info {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      notes {
        createdAt
        createdBy
        entry
        id
        key
        message
        noteAttachment {
          contentType
          key
          storage
          type
        }
        updatedAt
      }
    }
  }
`;
export const getInteractionHandledByMe = /* GraphQL */ `
  query GetInteractionHandledByMe(
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getInteractionHandledByMe(
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      nextToken
    }
  }
`;
export const getInteractionHandledByMeWithTypeTime = /* GraphQL */ `
  query GetInteractionHandledByMeWithTypeTime(
    $limit: Int
    $nextToken: String
    $timeFrom: String
    $timeTo: String
    $type: String
    $workspace: String!
  ) {
    getInteractionHandledByMeWithTypeTime(
      limit: $limit
      nextToken: $nextToken
      timeFrom: $timeFrom
      timeTo: $timeTo
      type: $type
      workspace: $workspace
    ) {
      items {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      nextToken
    }
  }
`;
export const getInteractionOwnedBy = /* GraphQL */ `
  query GetInteractionOwnedBy(
    $customer: String!
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getInteractionOwnedBy(
      customer: $customer
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      nextToken
    }
  }
`;
export const getInteractionSession = /* GraphQL */ `
  query GetInteractionSession($sessionId: String!, $workspace: String!) {
    getInteractionSession(sessionId: $sessionId, workspace: $workspace) {
      channel
      createdAt
      direction
      disposition
      entry
      from {
        display
        id
        type
      }
      handledBy
      host
      id
      key
      lastHandledBy {
        handledAs
        handledAt
        id
      }
      message
      otherData
      serverTimeStamp
      sessionId
      suggestions {
        contact
        customer
        display
        type
      }
      to {
        display
        id
        type
      }
      type
      updatedAt
      updatedBy
      workspace
    }
  }
`;
export const getLatestConversations = /* GraphQL */ `
  query GetLatestConversations(
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getLatestConversations(
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        conId
        entry
        isActive
        key
        lastContact {
          channel
          contact
          display
          id
          type
        }
        lastMessage {
          conId
          contentType
          message
          messageId
          messageType
          receivedAt
          sequence
          type
          workspace
        }
        lastReadMessageId
        lastReceivedMessageId
        lastSendMessageId
        partyId
        partyType
        preferredContact {
          channel
          contact
          display
          id
          type
        }
        unreadCount
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLatestInteractions = /* GraphQL */ `
  query GetLatestInteractions(
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getLatestInteractions(
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      nextToken
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages(
    $convId: String!
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getMessages(
      convId: $convId
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        conId
        contentType
        from {
          channel
          contact
          display
          id
          type
        }
        interaction {
          channel
          createdAt
          from
          handler
          id
          interactionTime
          sessionId
          status
          to
          updatedAt
        }
        message
        messageId
        messageType
        receivedAt
        sequence
        to {
          channel
          contact
          display
          id
          type
        }
        type
        workspace
      }
      nextToken
    }
  }
`;
export const getMyInvitations = /* GraphQL */ `
  query GetMyInvitations {
    getMyInvitations {
      acceptedAt
      invitedAt
      invitedUser
      invitedUserDisplay
      isInviteMailSend
      key
      message
      role
      status
      workspace
    }
  }
`;
export const getMyPackage = /* GraphQL */ `
  query GetMyPackage($workspace: String!) {
    getMyPackage(workspace: $workspace) {
      active
      boughtAt
      package
      packageDetails {
        code
        description
        display
        price {
          currency
          initial
          recurrent
        }
        resources {
          description
          level
          owners
          resource
        }
      }
    }
  }
`;
export const getMyProductivity = /* GraphQL */ `
  query GetMyProductivity($date: String!, $workspace: String) {
    getMyProductivity(date: $date, workspace: $workspace) {
      User_Productivity_Index
      User_Productivity_SK
      acwTime
      answeredWithinSLA
      breakTime
      entry
      firstLoginAt
      holdTime
      inboundAnsweredCount
      inboundCallCount
      inboundTalkTime
      key
      lastLoginAt
      lastLoginSession
      lastStateUpdatedAt
      loginCount
      missedCallCount
      outboundAnsweredCount
      outboundCallCount
      outboundTalkTime
      staffTime
      talkTime
      userName
      userState
      workspace
    }
  }
`;
export const getMyRoles = /* GraphQL */ `
  query GetMyRoles($limit: Int, $nextToken: String) {
    getMyRoles(limit: $limit, nextToken: $nextToken) {
      items {
        Member_Role_Index
        Member_Status_Role
        Member_Workspace
        invitedAt
        isActive
        isCustomRole
        joinedAt
        joinedMethod
        role
        state
        userId
      }
      nextToken
    }
  }
`;
export const getMySessions = /* GraphQL */ `
  query GetMySessions($limit: Int, $nextToken: String, $workspace: String!) {
    getMySessions(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
      items {
        channel
        createdAt
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy
        host
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        otherData
        serverTimeStamp
        sessionId
        suggestions {
          contact
          customer
          display
          type
        }
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      nextToken
    }
  }
`;
export const getMyTeams = /* GraphQL */ `
  query GetMyTeams($limit: Int, $nextToken: String, $workspace: String) {
    getMyTeams(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
      items {
        Team_Member_MYTeam_Index
        Team_Member_Team_Name
        createdAt
        entry
        key
        role
        teamName
        user
      }
      nextToken
    }
  }
`;
export const getMyUserProfile = /* GraphQL */ `
  query GetMyUserProfile {
    getMyUserProfile {
      consoleStatus {
        entry
        key
        lastRoute
        lastWorkspace
        properLogoff
        status
        statusAt
      }
      info {
        address
        appData
        createdAt
        metaData
        otherContacts
        phoneNumber
        userName
      }
      invitations {
        acceptedAt
        invitedAt
        invitedUser
        invitedUserDisplay
        isInviteMailSend
        key
        message
        role
        status
        workspace
      }
      mode {
        createdAt
        entry
        eventAt
        eventId
        key
        lastLoginAt
        mode
        reason
        sessionId
        status
        teams {
          Team_Member_MYTeam_Index
          Team_Member_Team_Name
          createdAt
          entry
          key
          role
          teamName
          user
        }
        updatedAt
        user {
          address
          appData
          createdAt
          metaData
          otherContacts
          phoneNumber
          userName
        }
        workspace
      }
      roles {
        Member_Role_Index
        Member_Status_Role
        Member_Workspace
        invitedAt
        isActive
        isCustomRole
        joinedAt
        joinedMethod
        role
        state
        userId
      }
    }
  }
`;
export const getNodes = /* GraphQL */ `
  query GetNodes(
    $flowId: String!
    $limit: Int
    $nextToken: String
    $version: String!
    $workspace: String!
  ) {
    getNodes(
      flowId: $flowId
      limit: $limit
      nextToken: $nextToken
      version: $version
      workspace: $workspace
    ) {
      items {
        Flow_Node_Edge_Source
        Flow_Node_Edge_Target
        Flow_Node_Source_Entry
        Flow_Node_Source_Index
        category
        data
        design
        destination {
          event
          point
          pointHandler
        }
        entry
        flowId
        id
        key
        nodeDisplay
        nodeName
        nodeType
        position {
          x
          y
        }
        source {
          event
          point
          pointHandler
        }
        type
      }
      nextToken
    }
  }
`;
export const getNotificationCount = /* GraphQL */ `
  query GetNotificationCount($workspace: String) {
    getNotificationCount(workspace: $workspace) {
      entry
      key
      readAt
      unreadCount
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($limit: Int, $nextToken: String, $workspace: String) {
    getNotifications(
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        createdAt
        createdBy
        message
        notificationId
        priority
        receiver
        status
        type
      }
      nextToken
    }
  }
`;
export const getOngoingConversations = /* GraphQL */ `
  query GetOngoingConversations(
    $limit: Int
    $nextToken: String
    $type: String
    $workspace: String!
  ) {
    getOngoingConversations(
      limit: $limit
      nextToken: $nextToken
      type: $type
      workspace: $workspace
    ) {
      items {
        conId
        entry
        isActive
        key
        lastContact {
          channel
          contact
          display
          id
          type
        }
        lastMessage {
          conId
          contentType
          message
          messageId
          messageType
          receivedAt
          sequence
          type
          workspace
        }
        lastReadMessageId
        lastReceivedMessageId
        lastSendMessageId
        partyId
        partyType
        preferredContact {
          channel
          contact
          display
          id
          type
        }
        unreadCount
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPendingInvitations = /* GraphQL */ `
  query GetPendingInvitations(
    $limit: Int
    $nextToken: String
    $workspace: String
  ) {
    getPendingInvitations(
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        acceptedAt
        invitedAt
        invitedUser
        invitedUserDisplay
        isInviteMailSend
        key
        message
        role
        status
        workspace
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($user: String!) {
    getProfile(user: $user) {
      consoleStatus {
        entry
        key
        lastRoute
        lastWorkspace
        properLogoff
        status
        statusAt
      }
      info {
        address
        appData
        createdAt
        metaData
        otherContacts
        phoneNumber
        userName
      }
      invitations {
        acceptedAt
        invitedAt
        invitedUser
        invitedUserDisplay
        isInviteMailSend
        key
        message
        role
        status
        workspace
      }
      mode {
        createdAt
        entry
        eventAt
        eventId
        key
        lastLoginAt
        mode
        reason
        sessionId
        status
        teams {
          Team_Member_MYTeam_Index
          Team_Member_Team_Name
          createdAt
          entry
          key
          role
          teamName
          user
        }
        updatedAt
        user {
          address
          appData
          createdAt
          metaData
          otherContacts
          phoneNumber
          userName
        }
        workspace
      }
      roles {
        Member_Role_Index
        Member_Status_Role
        Member_Workspace
        invitedAt
        isActive
        isCustomRole
        joinedAt
        joinedMethod
        role
        state
        userId
      }
    }
  }
`;
export const getRecentConversations = /* GraphQL */ `
  query GetRecentConversations(
    $limit: Int
    $nextToken: String
    $type: String
    $workspace: String!
  ) {
    getRecentConversations(
      limit: $limit
      nextToken: $nextToken
      type: $type
      workspace: $workspace
    ) {
      items {
        conId
        entry
        isActive
        key
        lastContact {
          channel
          contact
          display
          id
          type
        }
        lastMessage {
          conId
          contentType
          message
          messageId
          messageType
          receivedAt
          sequence
          type
          workspace
        }
        lastReadMessageId
        lastReceivedMessageId
        lastSendMessageId
        partyId
        partyType
        preferredContact {
          channel
          contact
          display
          id
          type
        }
        unreadCount
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($user: String, $workspace: String!) {
    getRole(user: $user, workspace: $workspace) {
      Member_Role_Index
      Member_Status_Role
      Member_Workspace
      invitedAt
      isActive
      isCustomRole
      joinedAt
      joinedMethod
      role
      state
      userId
    }
  }
`;
export const getRoleMeta = /* GraphQL */ `
  query GetRoleMeta($isSystem: Boolean!, $role: String!, $workspace: String!) {
    getRoleMeta(isSystem: $isSystem, role: $role, workspace: $workspace) {
      createdAt
      description
      isEnabled
      isSystem
      memberCount
      members {
        Member_Role_Index
        Member_Status_Role
        Member_Workspace
        invitedAt
        isActive
        isCustomRole
        joinedAt
        joinedMethod
        role
        state
        userId
      }
      permissions {
        description
        destroy
        list
        read
        resource
        write
      }
      role
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles($limit: Int, $nextToken: String, $workspace: String) {
    getRoles(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
      items {
        Member_Role_Index
        Member_Status_Role
        Member_Workspace
        invitedAt
        isActive
        isCustomRole
        joinedAt
        joinedMethod
        role
        state
        userId
      }
      nextToken
    }
  }
`;
export const getRolesByStatus = /* GraphQL */ `
  query GetRolesByStatus(
    $limit: Int
    $nextToken: String
    $status: String!
    $workspace: String!
  ) {
    getRolesByStatus(
      limit: $limit
      nextToken: $nextToken
      status: $status
      workspace: $workspace
    ) {
      items {
        Member_Role_Index
        Member_Status_Role
        Member_Workspace
        invitedAt
        isActive
        isCustomRole
        joinedAt
        joinedMethod
        role
        state
        userId
      }
      nextToken
    }
  }
`;
export const getRolesByType = /* GraphQL */ `
  query GetRolesByType(
    $limit: Int
    $nextToken: String
    $role: String!
    $workspace: String!
  ) {
    getRolesByType(
      limit: $limit
      nextToken: $nextToken
      role: $role
      workspace: $workspace
    ) {
      items {
        Member_Role_Index
        Member_Status_Role
        Member_Workspace
        invitedAt
        isActive
        isCustomRole
        joinedAt
        joinedMethod
        role
        state
        userId
      }
      nextToken
    }
  }
`;
export const getStorageItemMeta = /* GraphQL */ `
  query GetStorageItemMeta($key: String!, $workspace: String!) {
    getStorageItemMeta(key: $key, workspace: $workspace) {
      items {
        bucket
        contentType
        createdAt
        display
        file
        owner
        size
        type
        versionId
        workspace
      }
      nextToken
    }
  }
`;
export const getStorageTypeMeta = /* GraphQL */ `
  query GetStorageTypeMeta(
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getStorageTypeMeta(
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        acceptedContentTypes
        deleteEnabled
        downloadEnabled
        entry
        key
        name
        owner
        statistics {
          category
          count
          entry
          key
          lastUpdatedAt
          total
        }
        uploadEnabled
        viewEnabled
        workspace
      }
      nextToken
    }
  }
`;
export const getSystemPackages = /* GraphQL */ `
  query GetSystemPackages {
    getSystemPackages {
      items {
        code
        description
        display
        price {
          currency
          initial
          recurrent
        }
        resources {
          description
          level
          owners
          resource
        }
      }
      nextToken
    }
  }
`;
export const getTags = /* GraphQL */ `
  query GetTags(
    $limit: Int
    $name: String
    $nextToken: String
    $type: String
    $workspace: String!
  ) {
    getTags(
      limit: $limit
      name: $name
      nextToken: $nextToken
      type: $type
      workspace: $workspace
    ) {
      items {
        description
        isActive
        name
        type
        workspace
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($name: String!, $workspace: String!) {
    getTeam(name: $name, workspace: $workspace) {
      createdAt
      createdBy
      description
      enabled
      entry
      key
      members {
        Team_Member_MYTeam_Index
        Team_Member_Team_Name
        createdAt
        entry
        key
        role
        teamName
        user
      }
      name
      numbers
      setting
      workspace
    }
  }
`;
export const getTeamMembers = /* GraphQL */ `
  query GetTeamMembers(
    $limit: Int
    $nextToken: String
    $team: String!
    $workspace: String!
  ) {
    getTeamMembers(
      limit: $limit
      nextToken: $nextToken
      team: $team
      workspace: $workspace
    ) {
      items {
        Team_Member_MYTeam_Index
        Team_Member_Team_Name
        createdAt
        entry
        key
        role
        teamName
        user
      }
      nextToken
    }
  }
`;
export const getTeams = /* GraphQL */ `
  query GetTeams($limit: Int, $nextToken: String, $workspace: String!) {
    getTeams(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
      items {
        createdAt
        createdBy
        description
        enabled
        entry
        key
        members {
          Team_Member_MYTeam_Index
          Team_Member_Team_Name
          createdAt
          entry
          key
          role
          teamName
          user
        }
        name
        numbers
        setting
        workspace
      }
      nextToken
    }
  }
`;
export const getUnreadConversations = /* GraphQL */ `
  query GetUnreadConversations(
    $limit: Int
    $nextToken: String
    $workspace: String!
  ) {
    getUnreadConversations(
      limit: $limit
      nextToken: $nextToken
      workspace: $workspace
    ) {
      items {
        conId
        entry
        isActive
        key
        lastContact {
          channel
          contact
          display
          id
          type
        }
        lastMessage {
          conId
          contentType
          message
          messageId
          messageType
          receivedAt
          sequence
          type
          workspace
        }
        lastReadMessageId
        lastReceivedMessageId
        lastSendMessageId
        partyId
        partyType
        preferredContact {
          channel
          contact
          display
          id
          type
        }
        unreadCount
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserInteractionEvents = /* GraphQL */ `
  query GetUserInteractionEvents(
    $date: String!
    $user: String!
    $workspace: String!
  ) {
    getUserInteractionEvents(date: $date, user: $user, workspace: $workspace) {
      createdAt
      entry
      event
      eventAt
      eventData
      eventId
      eventTime
      key
      sessionId
      workspace
    }
  }
`;
export const getUserModes = /* GraphQL */ `
  query GetUserModes($limit: Int, $nextToken: String, $workspace: String!) {
    getUserModes(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
      items {
        createdAt
        entry
        eventAt
        eventId
        key
        lastLoginAt
        mode
        reason
        sessionId
        status
        teams {
          Team_Member_MYTeam_Index
          Team_Member_Team_Name
          createdAt
          entry
          key
          role
          teamName
          user
        }
        updatedAt
        user {
          address
          appData
          createdAt
          metaData
          otherContacts
          phoneNumber
          userName
        }
        workspace
      }
      nextToken
    }
  }
`;
export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($workspace: String) {
    getWorkspace(workspace: $workspace) {
      address {
        city
        country
        number
        state
        street
        type
        zipCode
      }
      avatar
      createdAt
      custom_roles
      description
      enabled
      entry
      key
      owner
      ownerId
      system_roles
      timezone
      updatedAt
      website
    }
  }
`;
export const getWorkspaceProductivity = /* GraphQL */ `
  query GetWorkspaceProductivity(
    $dateFilterValue: String!
    $limit: Int
    $mode: DateFilterMode!
    $nextToken: String
    $user: String
    $workspace: String!
  ) {
    getWorkspaceProductivity(
      dateFilterValue: $dateFilterValue
      limit: $limit
      mode: $mode
      nextToken: $nextToken
      user: $user
      workspace: $workspace
    ) {
      items {
        User_Productivity_Index
        User_Productivity_SK
        acwTime
        answeredWithinSLA
        breakTime
        entry
        firstLoginAt
        holdTime
        inboundAnsweredCount
        inboundCallCount
        inboundTalkTime
        key
        lastLoginAt
        lastLoginSession
        lastStateUpdatedAt
        loginCount
        missedCallCount
        outboundAnsweredCount
        outboundCallCount
        outboundTalkTime
        staffTime
        talkTime
        userName
        userState
        workspace
      }
      nextToken
    }
  }
`;
export const numberSearch = /* GraphQL */ `
  query NumberSearch(
    $country: String
    $limit: Int
    $offset: Int
    $pattern: String
    $provider: String!
    $region: String
    $services: String
    $type: String
    $workspace: String!
  ) {
    numberSearch(
      country: $country
      limit: $limit
      offset: $offset
      pattern: $pattern
      provider: $provider
      region: $region
      services: $services
      type: $type
      workspace: $workspace
    ) {
      error
      items {
        city
        compliance
        country
        lata
        mms
        mmsRate
        monthlyRate
        number
        region
        restriction
        setupRate
        sms
        smsRate
        subType
        type
        url
        voice
        voiceRate
      }
      totalCount
    }
  }
`;
export const search = /* GraphQL */ `
  query Search(
    $content: String!
    $field: String
    $index: String
    $workspace: String
  ) {
    search(
      content: $content
      field: $field
      index: $index
      workspace: $workspace
    ) {
      description
      id
      score
      source
      type
      workspace
    }
  }
`;
