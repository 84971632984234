import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import  {getAllFlows}  from "../../../graphql/queries";

export const flowsApi = createApi({
  baseQuery: fetchBaseQuery({
    query: getAllFlows,
  }),
  endpoints: (builder) => ({
    getAllFlows: builder.query({
      query: getAllFlows,
      providesTags: ["flows"],
    }),
  }),
});


export const { useGetAllFlowsQuery } = flowsApi;
