import { API } from "aws-amplify";
import { getMyUserProfile } from "../../graphql/queries";

export default new (class UserService {    

  getMyProfile = async () => {
    try {
      
      const response = await API.graphql({ query: getMyUserProfile, variables: {limit:100} });
      console.log( response.data.getMyUserProfile);
      return response.data.getMyUserProfile ;     
      
    } catch (error) {
        console.error("UserService", "getMyProfile", error);
        return undefined
    }
  };


})();
