import React, { useEffect, useState } from "react";
import ReactFlow, {
  applyNodeChanges,
  addEdge,
  MarkerType,
  Controls,
  Background
} from "reactflow";
import { useSelector, useDispatch } from "react-redux";
import ConfigurationNode from "./NodeFactory/ConfigurationNode";
import StartNode from "./NodeFactory/StartNode";
import EndNode from "./NodeFactory/EndNode";
import { onFlowRestored, onNodeDicConnected, onNodeRemoved } from "../../redux/features/nodeConfigSlice";
import nodeService from "../../service/nodeService";
import "../../../assets/stylesheets/nodes.scss";
import { useLocation, useNavigate, useParams,useSearchParams } from "react-router-dom";
//import Radio from "antd/lib/radio";
import Popconfirm from "antd/lib/popconfirm";
import message from "antd/lib/message";
import Button from "antd/lib/button";
// import Typography from "antd/lib/typography";
// import Select from "antd/lib/select";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import Spin from "antd/lib/spin";
import { showPanel,getFilesForType,getTeams,onSetFlowDetails } from "../../redux/features/nodeConfigSlice";

const nodeTypes = { node: ConfigurationNode, start:StartNode , end:EndNode };

let y = 10;
let ref_input = React.createRef();
let newFlow = undefined;

function IVR() {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const user = useSelector((state)=> state.user);
  const nodeConfig = useSelector((state)=>state.nodeConfig);
  
  const location = useLocation();
  const [form] = Form.useForm();
  //const { Title } = Typography;

  const [searchParams] = useSearchParams();  

  const { flowId  } = useParams();  
  const [elements, setElements] = useState([]);  
  const [configurations, setConfigurations] = useState({
    isDraggable: true,
    showConfigurationsPanel: false,
    configurations: {},
  });
  const [rfInstance, setRfInstance] = useState(null);
  const [loading,setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isNewFlow, setIsNewFlow] = useState(location.pathname.indexOf("/console/flow/new/") === 0);


  const onElementsRemove = (elementsToRemove) =>{
    setElements((els) => applyNodeChanges(elementsToRemove, els));
    dispatch(onNodeDicConnected(elementsToRemove[0]));
    dispatch(onNodeRemoved({elements:elementsToRemove}));
    console.log("onElementsRemove",elementsToRemove);
  }
    
  //<Form initialValues={{ yourDesiredFormInputName: true }} ><Form.Item name="yourDesiredFormInputName" label="My Form Input"><Input /></Form.Item></Form>
  const onConnect = (params)=> {
    Modal.success({
      title: 'Enter action',
      content: <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={{
        modifier: 'public',labelName:"invoke"
      }}
    >
      <Form.Item
        name="labelName"
        rules={[
          {
            required: true,
            message: 'Please enter lable name!',
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>,
      onOk:async () => {
        return new Promise((resolve,reject) =>
        form
        .validateFields()
        .then((values) => {
          form.resetFields();
          setElements((els) =>addEdge({ ...params, MarkerType: MarkerType.ArrowClosed ,type:"edge",nodeType:"edge",category:"EDGE",data:"",design:"",label: values.labelName,labelStyle: { fill: 'red', fontWeight: 700 }}, els))
          resolve();
        })
        .catch((info) => {
          console.log('Validate Failed:', info);
          reject();
        })
        );
      },
    });
  }
//  const onConnect = (params) => setElements((els) =>addEdge({ ...params, MarkerType: MarkerType.ArrowClosed ,type:"edge",nodeType:"edge",category:"EDGE",data:"",design:""}, els));
//commented below because it was not being used 
//const onConnectmmm = (params) => setElements((els) =>addEdge({ ...params, MarkerType: MarkerType.ArrowClosed ,type:"edge",nodeType:"edge",category:"EDGE",data:"",design:"",label: 'bezier edge (default)',labelStyle: { fill: 'red', fontWeight: 700 }}, els));  
  
  
  const onUpdateNode = (data) => {
    try {      
      const el =
        elements &&
        elements.map((currentNode) => {
          if (currentNode.id === data.id) {
            const nd = {...currentNode,data: {...currentNode.data, ...data}};
            return nd;
          }
          return currentNode;
        });
      setElements(el);
    } catch (error) {
      console.error("onSubmit", error);
    }    
  };

  const addNode = (node) => {
    try {
      const nodes = [...elements]; //"position": { "x": 10, "y": 10 }
      const nd = { ...node, position: { x: 10, y: y } };
      y = y + 75;
      nodes.push(nd);
      setElements([...nodes]);
    } catch (error) {
      console.error("addNode", error);
    }
  };

  const loadAvailableElements = async () => {
    console.debug("main",`loading flow :${flowId}->version :${nodeConfig.flowDetails.version}.......`);
    const el =
      elements &&
      elements.map((node) => {
        node.position.y = y;
        y = y + 75;
        return node;
      });
    setElements(el);

    if(flowId){
      let tempVersion = nodeConfig.flowDetails.version;
      if(nodeConfig.flowDetails.version === "0" || nodeConfig.flowDetails.version === undefined || nodeConfig.flowDetails.version === null){
        tempVersion = await getVersions();
        console.debug("main",`updating version :${flowId}->version :${tempVersion}.......`);
      } 
      onRestore(tempVersion);           
    }else{
      message.error("Unable to find flow id");
      navigate({pathname:"/404"});
    }
  };

  const updateConfigurations = (data) => {
    try {
      if (data) {
        setConfigurations({
          isDraggable: !data.config,
          showConfigurationsPanel: data.config,
          configurations: data.configurations,
          data: data,
        });
      }
    } catch (error) {
      console.error("updateConfigurations", error);
    }
  };

  const getVersions = async()=>{
    try {
      const flows = await nodeService.getFlows(user.currentWorkspace,flowId);
      if(flows && flows.versions){        
        const maxVersion = Math.max(...flows.versions.map(o => o.version));
        const name = flows.flow?.name;
        dispatch(onSetFlowDetails({flowName:name,version:maxVersion,versions:flows.versions?.sort((a,b) => a.version - b.version)}));   //flows.versions.sort((a,b) => a.version - b.version);
        return maxVersion
      }
    } catch (error) {
      console.error("getVersions", error);
    }
    return 0;
  }

  const onRestore = async (version) => {
    try {
      setLoading(true);
      setElements([]);
      const nodes = await nodeService.getNodes(user.currentWorkspace,flowId,version);
      if (nodes) {
        const elements = nodes.map((node)=>
          {
            if(node.type === "edge"){
              const data = JSON.parse(node.data) ;
              return {
                source: node.source?.point??"",
                sourceHandle: node.source?.pointHandler??"",
                target: node.destination?.point??"",
                targetHandle: node.destination?.pointHandler??"",
                MarkerType: "arrowclosed",
                type: node.type,
                nodeType:node.nodeType,
                category: node.category,
                data,
                design:JSON.parse(node.design) ,
                id: node.source?.event??"",
                multiple : data.supportMultipleNode?? false,
                label : node.nodeDisplay,
                labelStyle : data.labelStyle
              }
            }else{
              return {
               id: node.id,
               type: node.type,
               category:node.category,
               nodeType:node.nodeType,
               multiple : false,
               data: JSON.parse(node.data),
               position: node.position
             }
            }           
           });
         setElements(elements);
         setIsNewFlow(false); 
         dispatch(onFlowRestored({elements}));           
      }     
      setLoading(false);  
    } catch (error) {
      console.error("onRestore", error);
      setLoading(false);
    }
    finally{
      console.debug("main",`FLow loaded :${flowId}->version :${version}.......`);
    }
    
  };

  const onSave = async () => {
    try {
      setLoading(true);
      if (rfInstance) {
        if(flowId){
          const flow = rfInstance.toObject();
          if(flow.elements.length === 0){
            message.error('No elements to save, please update your flow design');
            return;
          }
          if(!newFlow){
            newFlow =  await  nodeService.saveNewFlowVersion(user.currentWorkspace,flowId,nodeConfig.flowDetails.flowName);            
          }                   
          const response =  await  nodeService.saveFlowNodes(user.currentWorkspace,flowId,newFlow.version,flow.elements);           
          if(response === true){
            dispatch(onSetFlowDetails({flowName:nodeConfig.flowDetails.flowName,version:newFlow.version,versions:[...nodeConfig.flowDetails.versions,...[newFlow]]}));  
            newFlow = undefined;
            message.success('Flow update successfully');
            return;
          }
          message.error('Fail update flow design, please try agin');
        }else{
          message.error('Fail to find flow ID');
          newFlow = undefined;
        }        
      }
    } catch (error) {
      console.error("onSave", error);
      newFlow = undefined;
      message.error('Fail update flow');
    }
    finally{
      setLoading(false);
      setIsNewFlow(false); 
    }
  };
  const onPublish = async () => {
    try {
      setLoading(true);
      if (rfInstance) {
        if(flowId){             
          const response =  nodeService.publishFlow(user.currentWorkspace,flowId,nodeConfig.flowDetails.version);
          if(response){
            message.success('Successfully published');
            return;
          }
          message.error('Fail to publish');
        }else{
          message.error('Fail to find flow ID');
        }
        
      }
    } catch (error) {
      console.error("onPublish", error);
      message.error('Fail to publish');
    }
    finally{
      setLoading(false);
      setIsNewFlow(false); 
    }
  }
 
  useEffect( () => {
    try{
      const flows = nodeService.getFlows(user.currentWorkspace,flowId);
      if(flows && flows.versions){
        console.log("flows",flows);
      }
    }catch(error){
      console.error("getVersions", error);
    }
  }, [flowId]);

  useEffect(() => {
    if(nodeConfig.flowDetails?.version){
      onRestore(nodeConfig.flowDetails?.version);
    }
  }, [nodeConfig.flowDetails?.version]); 

  useEffect(() => {
    updateConfigurations(nodeConfig.payload);
  }, [nodeConfig.payload]);

  useEffect(() => {
    addNode(nodeConfig.node);
  }, [nodeConfig.node]);  
  
  useEffect(() => {
    onUpdateNode(nodeConfig.nodeData);
  }, [nodeConfig.nodeData]);

  useEffect(() => {
    dispatch(showPanel({activeId: null }));
  }, [loading]);

  useEffect(() => {
    try {
      if(user.currentWorkspace)
        {
          dispatch(getFilesForType(user.currentWorkspace));
          dispatch(getTeams(user.currentWorkspace));
        }
    } catch (error) {
      console.error("loadClips", error);
    }
  }, [user.currentWorkspace]);

  useEffect( () => {
  dispatch(onSetFlowDetails({flowName:location?.state?.flow?.name ?? searchParams.get("name"),version:location?.state?.flow?.version ?? searchParams.get("version")}));       
    if(location?.state?.flow){      
      loadAvailableElements();      
    }
    else{
      setTimeout(() => {
        console.debug("------------------------------------------------- WAITING TO APPLY SECURITY TOKENS -------------------------------------------------");
        ref_input.click();
        ref_input.dispatchEvent(new Event("onClick")); 
      }, 2000);
    }
  }, []);
 
 
  return (
    <>   
      <Row>
        <Button style={{display:"none"}} ref={(ref) => (ref_input = ref)} onClick={()=>loadAvailableElements(nodeConfig.flowDetails.version)} size="small" type="dashed" danger >Button to handle issue with admin ui navigation auth issue</Button>
        
        <Col span={24} style={{marginBottom:"10px"}}>          
          <Form layout="inline" style={{float:"right"}}>  
            <Form.Item>
              <Popconfirm
                  title="Are you sure you want to save changes?"
                  onConfirm={()=>onSave()}
                  onCancel={()=>console.log("onCancel")}
                  okText="Yes"
                  cancelText="No"
                >
                {/* <Radio.Button value="small" >Save</Radio.Button> */}
                <Button  type="primary" loading={loading}>Update flow</Button>
              </Popconfirm>
            </Form.Item>                        
            <Form.Item style={{marginRight: "0"}}>
              <Popconfirm
                title="Do you want to publish current FLOW?"
                onConfirm={()=>onPublish()}
                onCancel={()=>console.log("onCancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button   type="primary"  danger loading={loading} disabled={nodeConfig.flowDetails.version === 0}>Publish</Button>
              </Popconfirm> 
            </Form.Item>    
          </Form>
        </Col>
      </Row> 
      { loading && <Spin tip="Loading..." className="flow-list-spin"></Spin>}
      <ReactFlow
        elements={elements}
        onElementsRemove={onElementsRemove}
        onConnect={onConnect}
        deleteKeyCode={46}
        nodeTypes={nodeTypes}
        onLoad={setRfInstance}
        snapToGrid={true}
        snapGrid={[10, 10]}
        nodesDraggable={configurations.isDraggable}
        className="touchdevice-flow"
        style={{height:"95%"}}
        // className="ant-card ant-card-bordered"
      >
        
        <Controls />
        <Background
          color="#e2ddff"
          gap={20}
          variant="dots"
          size={2}
          style={{ backgroundColor: '#f1efff' }} />
      </ReactFlow>
    </>
  );
}

export default IVR;
