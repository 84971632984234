const awsmobile = {
  aws_appsync_graphqlEndpoint:
    "https://3ovk7dnh3re65mfszivflyvjpi.appsync-api.us-west-1.amazonaws.com/graphql",
  aws_user_pools_id: "us-west-1_15sCRnpvX",
  aws_user_pools_web_client_id: "dcp4t80uhl2h91plr8gim270n",
  aws_appsync_region: "us-west-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-nf6kfjjb6ramfhyapsms4pnqfq",
};

export default awsmobile;
