import { API } from "aws-amplify";
import { getAllFlows } from "../../graphql/queries";

class FlowsService {
  async getAllFlows(limit, nextToken, workspace) {
    try {
      const response = await API.graphql({
        query: getAllFlows,
        variables: {
          limit,
          nextToken,
          workspace,
        },
      });

      return response.data.getAllFlows.items;
    } catch (error) {
      console.error("FlowsService", "getAllFlows", error);
      throw error;
    }
  }
}

export default new FlowsService();
