import React from "react"; 
import Result from "antd/lib/result";
import Spin from "antd/lib/spin";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = ()=>{
    console.debug("Logout ----------------------------------------------------------------------");     
    const navigate = useNavigate();
       
    useEffect(() => {
        const signOut = async () => {
           await Auth.signOut();
        };      
        signOut(); 
      
        return () => {
            navigate({pathname:"/"});
        };
    }, []);

    return (
        <Result
            icon={<Spin size="large" />}
            title="Processing..."
        />
    )
}

export default Logout;