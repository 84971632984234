import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userService from "../../service/userService";


const initialState = {  
  workspaces:[],
  currentWorkspace:""
}

export const getMyProfile = createAsyncThunk(
  "users/getMyProfile",
  async () => {
    try {
      const response = await userService.getMyProfile();
      return response;
    } catch (error) {
      return undefined;
    }
  }
);

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    onSelectWorkspace:(state, action)=>{
      state.currentWorkspace = action.payload.workspace
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyProfile.fulfilled, (state, action) => {
      state.workspaces = action.payload.roles?.map((w)=> w.Member_Workspace);
      //state.currentWorkspace = action.payload.consoleStatus?.lastWorkspace;
    })
  }
})

export const { onSelectWorkspace} = userSlice.actions

export default userSlice.reducer