import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./features/counter/counterSlice";
import nodeConfigReducer from "../redux/features/nodeConfigSlice";
import userSlice from "../redux/features/userSlice";
import {flowsApi} from "../redux/features/flowsSlice"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    nodeConfig: nodeConfigReducer,
    user:userSlice,
    flows: flowsApi
  },
  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(flowsApi.middleware), // Add flowsApi.middleware
});
