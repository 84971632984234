import { API } from "aws-amplify";
import { getActiveFlow, getAllFlows, getFlow, getFlows, getNodes, getTags, getUserModes,getFilesForType,getTeams } from "../../graphql/queries";
import { createFlow, publishFlow, saveFlowNodes, saveNewFlowVersion} from "../../graphql/mutations";

export default new (class NodeService {
  
  /* getData = () => {
    return fetch("nodes.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return myJson;
      })
      .catch((error) => {
        return null;
      });
  }; */

  getAllFlows = async (workspace) => {
    try {
      const response = await API.graphql({ query: getAllFlows, variables: {workspace, limit:100} });
      return response.data.getAllFlows.items ;     
    } catch (error) {
        console.error("NodeService", "getAllFlows", error);
        return undefined
    }
  };

  getActiveFlow = async (workspace,flowId) => {
    try {
      const response = await API.graphql({ query: getActiveFlow, variables: {flowId,workspace} }) ;
      return response.data.getActiveFlow ;     
    } catch (error) {
        console.error("NodeService", "getActiveFlow", error);
        return error.data.getNodes
    }
  };

  getFlow = async (workspace,flowId,version) => {
    try {
      const response = await API.graphql({ query: getFlow, variables: {flowId,workspace,version} }) ;
      return response.data.getFlow ;     
    } catch (error) {
        console.error("NodeService", "getFlow", error);
        return error.data.getFlow
    }
  };

  getFlows = async (workspace,flowId) => {
    try {
      const response = await API.graphql({ query: getFlows, variables: {flowId,workspace} }) ;
      return response.data.getFlows ;     
    } catch (error) {
        console.error("NodeService", "getFlows", error);
        return undefined
    }
  };

  createFlow = async (workspace,name,type,description) => {
    try {
      const response = await API.graphql({ query: createFlow, variables: {flow: {description, name, type}, workspace} }) ;
      return response.data.createFlow ;     
    } catch (error) {
        console.error("NodeService", "createFlow", error);
        return undefined;
    }
  };
  
 /*  {
    "flowId": "ssss",
    "nodes": {
      "category": "fff",
      "data": "ff",
      "design": "ff",
      "destination": {
        "event": "fff",
        "point": "fff",
        "pointHandler": "ff"
      },
      "id": "ff",
      "nodeDisplay": "ff",
      "nodeName": "ff",
      "nodeType": "ff",
      "position": {
        "x": "ff",
        "y": "ff"
      },
      "source": {
        "event": "ff",
        "point": "ff",
        "pointHandler": "ff"
      },
      "type": "ff"
    },
    "version": 10,
    "workspace": "ff"
  } */
  saveFlowNodes = async (workspace,flowId,version,elements) => {    
    try {      
      let nodes = elements.map((el)=>{          
          return {           
            nodeName: el.id,
            nodeType:  el.nodeType,
            nodeDisplay: el.type === "edge"? el.label: el.id,
            id: el.id,
            category: el.category,
            design: JSON.stringify(el),
            data: JSON.stringify({...el.data,supportMultipleNode :el.multiple , labelStyle:el.labelStyle}),
            type: el.type,
            position: {x: el.position?.x?.toString()?? "" ,y: el.position?.y?.toString()?? ""},           
            source: {point: el.source ?? "", pointHandler: el.sourceHandle ?? "", event: el.type === "edge"? el.label: el.id},
            destination: {point: el.target ?? "", pointHandler: el.targetHandle ?? "", event: el.type === "edge"? el.label: el.id}
          }
      });

      const flowNodes = {
        flowId,
        version,
        workspace,
        nodes
      };     

      const response = await API.graphql({ query: saveFlowNodes, variables: {...flowNodes} }) ;
      if(response.data.saveFlowNodes.status === false)
      {
        return false;
      }
      else if(response.data.saveFlowNodes.object){
        const saveData = JSON.parse(response.data.saveFlowNodes.object);
        if(saveData.unprocessedItems?.Operational.length > 1 && (saveData.unprocessedItems?.Operational[0] !== undefined || saveData.unprocessedItems?.Operational[0] !== null)){
            return false;
        }
      }
      return true;
    } catch (error) {
        console.error("NodeService", "saveFlowNodes", error);
        return false
    }
  };

  
  saveNewFlowVersion = async (workspace,flowId,name) => {
    try {      
      const response = await API.graphql({ query: saveNewFlowVersion, variables: {workspace,flowId,name} }) ;
      return response.data.saveNewFlowVersion ;     
    } catch (error) {
        console.error("NodeService", "saveNewFlowVersion", error);
        return undefined;
    }
  };

  publishFlow = async (workspace,flowId,version) => {
    try {      
      const response = await API.graphql({ query: publishFlow, variables: {workspace,flowId, version} }) ;
      return response.data.publishFlow ;     
    } catch (error) {
        console.error("NodeService", "publishFlow", error);
        return undefined;
    }
  };

  getNodes = async (workspace,flowId,version) => {
    try {
      const response = await API.graphql({ query: getNodes, variables: {flowId,workspace,version,} }) ;
      return response.data.getNodes?.items ;     
    } catch (error) {
        console.error("NodeService", "getNodes", error);
        return undefined ; //error?.errors[0]?.errorType?? undefined;
    }
  };


  getUserModes = async (workspace) => {
    let users = [];
    try {
      
      const  loadData = async(nextToken)=>{
        const getUserModesQueryVariables = {
          workspace,nextToken
        };
        const response = await API.graphql({ query: getUserModes, variables: getUserModesQueryVariables }) ;
        const data = response.data.getUserModes ;
        
        if(data.items){
          users = [...users,...data.items];
        }
        if(data.nextToken){
          loadData(data.nextToken);
        }
      }
      await loadData(undefined);          
    } catch (error) {
        console.error("UserService", "getUserModes", error);
    }
      return users;//removed finally block  it gives and ESLint error which is eslint-disable
  
  }

  getCallTag = async (workspace) => {
    let tags =[];
    try {  
        
        const loadMore = async (nextToken)=>{
            let interaction = await API.graphql({ query: getTags, variables: {workspace , limit: 100,  nextToken} }) ;
            const data = interaction.data.getTags ;
            if(data.items ){
                data.items.map((i)=>{
                    if(i){
                        tags.push(i);
                    }
                })                   
                if(data.nextToken){
                    loadMore(data.nextToken);
                }
            }
        }          
        await loadMore("");            
    } catch (error) {
        console.error("TagService", "getCallTag", error);
    }
    return tags;
  }

  getFilesForType = async (workspace) => {
    let enumNames ={};
    let enumIds ={};
    let count=0;
    try {  
        
        const loadMore = async (nextToken)=>{
            let interaction = await API.graphql({ query: getFilesForType, variables: {workspace , category:"clips",limit:100, nextToken} }) ;
            const data = interaction.data.getFilesForType ;
            if(data.items ){
                data.items.map((i)=>{
                    if(i){
                      if(enumNames[i.display]){
                        count++;
                        enumNames[`${i.display}_${count}`]=i;
                      }else{
                        enumNames[i.display]=i;
                      }                      
                      enumIds[i.key]=i;
                    }
                })                   
                if(data.nextToken){
                    loadMore(data.nextToken);
                }
            }
        }          
        await loadMore("");            
    } catch (error) {
        console.error("TagService", "getFilesForType", error);
    }
    return {enumNames,enumIds};
  }

  getTeams = async (workspace) => {
    let enumNames ={};
    let enumIds ={};
    try {  
        
        const loadMore = async (nextToken)=>{
            let interaction = await API.graphql({ query: getTeams, variables: {workspace ,limit:1000, nextToken} }) ;
            const data = interaction.data.getTeams ;
            if(data.items){
                data.items.map((i)=>{
                  if(i && (i.enabled === true ||i.enabled === "true")){                    
                    enumNames[i.name]=i.name;                   
                    enumIds[i.name]=i.name;
                  }
                })                   
                if(data.nextToken){
                    loadMore(data.nextToken);
                }
            }
        }          
        await loadMore("");            
    } catch (error) {
        console.error("TagService", "getTeams", error);
    }
    return {enumNames,enumIds};
  }


})();
