import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import Handler from "./Handler";
//removed data prop because it was not being used
const StartNode = () => {
  return (
    <div title="Start" >      
      <FontAwesomeIcon icon={faIcons['faPlayCircle']} className="svg"/>
      <Handler index={"start"} type={"source"} position={"right"} id={`start`} edge={undefined} className={"output"}/>
    </div>
  );
};

export default StartNode;
