import Spin from "antd/lib/spin";
import Result from "antd/lib/result";
import React from "react";

const WelcomeMessage = () => {
  return <Result icon={<Spin size="large" />} title="Welcome to Flow Designer" />;
};

WelcomeMessage.displayName = "WelcomeMessage";

export default WelcomeMessage;
