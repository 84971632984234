import React, { memo, useEffect, useState,useCallback } from "react";
import {useUpdateNodeInternals } from "reactflow";
import { useSelector, useDispatch } from "react-redux";
import { onNodeDataUpdate, showPanel } from "../../../redux/features/nodeConfigSlice";
import BEMhelper from "react-bem-helper";
import More from '../../../../assets/images/icons/more.png'
import PluseIcon from '../../../../assets/images/icons/pluse-iocn.png'
import './node-types.scss'
import Handler from "./Handler";
import Form from "react-jsonschema-form";
import { Input } from 'antd';
import PropTypes from "prop-types";

const overwriteTo = {};
const overwroteData={};

const ConfigurationNode = memo(({ data }) => {
  const [config, setConfig] = useState(false);
  const dispatch = useDispatch();
  const updateNodeInternals = useUpdateNodeInternals();
  const st = useSelector((state) => state.nodeConfig);
  const [showFrom, setShowFrom] = useState(false);
  const [formData, setFormData] = useState(data?.configurations?.formData);
  const [formSchema,setFromSchema] = useState(data?.configurations?.schema);
  const target =  data?.configurations?.Handle?.target?? 1; // data?.configurations?.Handle?.target >= 0 ? data?.configurations?.Handle?.target : 1 ;
  const [source,setSource] = useState(data?.configurations?.Handle?.source??1) ;// useState(data?.configurations?.Handle?.source >= 0 ? data?.configurations?.Handle?.source : 1) ;


  const classes = new BEMhelper("welcomeNode");
  
  useEffect(() => {
    updateNodeInternals(data.id)
  }, [source])


  const appendData = useCallback(() => {
    try {
      let properties = data?.configurations?.schema?.properties;
      if(data?.configurations?.appendData && properties && st.configurationData){
        data.configurations.appendData.forEach((a)=>{
          if(properties[a.overwrite] && st.configurationData[a.dataType]){  
            if(a.overwriteTo){
              overwriteTo[a.overwriteTo] = a;
            }            
            const keys = Object.keys(st.configurationData[a.dataType].enumIds);
            if(keys.length > 0){
              properties = {...properties,...{[a.overwrite]:{...properties[a.overwrite],default:keys[0],enum:keys,enumNames:Object.keys(st.configurationData[a.dataType].enumNames)}}};
            }            
           //{...data?.configurations?.schema,"properties":{...properties,...{[a.overwrite]:{...properties[a.overwrite],enum:Object.keys(st.configurationData[a.dataType].enumIds),enumNames:Object.keys(st.configurationData[a.dataType].enumNames)}}}};
          }
        });
        const updateData = {...data.configurations?.schema,"properties":{...properties}};
        setFromSchema(updateData);
        overwriteData(properties);  
      }
    } catch (error) {
      console.error("ConfigurationNode","appendData",error);
    }
  }, [data,st.configurationData]);

  useEffect(() => {
    appendData();
  }, [])

  const overwriteData = (formData)=>{
    try {      
        Object.values(overwriteTo).forEach((i)=>{
          overwroteData["type"] = "s3";
          if(formData[i.overwrite]){
            overwroteData[i.overwriteTo] = formData[i.overwrite].enum? formData[i.overwrite].enum[0] : formData[i.overwrite];
          }
        });
    } catch (error) {
      console.error("ConfigurationNode","overwriteData",error);
    }
  }
  const  onFormSubmit = (event)=> {
    try {
      console.log("---Form submitted---",event.formData);
      setFormData(event.formData);
      setShowFrom(false);
      if(data){  
        overwriteData(event.formData);  
        const nd = {...data,...overwroteData,...event.formData,configurations:{...data.configurations,formData:event.formData}};
        console.log("---Form submitted-updated---",nd);
        dispatch(onNodeDataUpdate({nodeData:nd}));
      }
      console.log("---Form submitted-updated---",data);
      
    } catch (error) {
      console.error("onFormSubmit", error);
    } 
    finally{
      dispatch(showPanel({activeId: null }));
    }   
  }

  const onFormChange = (event)=> {
    console.log("---Form changed---");
    console.log(event.formData);
    setFormData(event.formData);    
  }

  const tryRequire = () => {
    try {
      return require(`../../../../assets/images/icons/${data.configurations.icon}.svg`);
    } catch (err) {
      return require("../../../../assets/images/icons/default.svg");
    }
  };

  const onNameChange =(e)=>{
    try {
      if(data){
        const nd = {...data,label:e.target.value};
        dispatch(onNodeDataUpdate({nodeData:nd}));
      }
    } catch (err) {
      console.log(err);
    }
  }
  const header = () => {
    console.log(data.configurations.icon,'data')
    
    return(
      <>
        <div {...classes('header')}>
          <div {...classes('left')}>
            <img src={tryRequire()} {...classes('header_icon')} />
            {!showFrom && <p {...classes('header_title')}>{data?.label?.charAt(0).toUpperCase() + data?.label?.slice(1)}</p>  }          
            {showFrom && <Input {...classes('header_title')} placeholder="Node Name" defaultValue={data?.label} onChange={(e)=>onNameChange(e)}/>}
          </div>
          {data?.configurations?.schema && 
            <div {...classes('right')}>
              {(st.payload === undefined || st.payload.activeId === data.id || st.payload.activeId === null) && (
                <a onClick={() => { dispatch(showPanel({ ...data, config: !config, activeId: config ? null : data.id, })); setConfig(!config); setShowFrom(!showFrom); }}>
                  <img src={More} {...classes('dots')} />
                </a>
              )}

            </div>
          }
        </div>
      </>
    )
  }

 
  const addSource = ()=>{
    try {
      const tempSource = source+1;
      const nd = {...data,configurations:{...data.configurations,Handle:{...data?.configurations?.Handle,source:tempSource}}};
      dispatch(onNodeDataUpdate({nodeData:nd}));
      setSource(tempSource);
    } catch (error) {
      console.error("fail to add new source",error);
    }
  }
  
  

  return (
    <>
    <div className="config-node-wrap">   
      <div className="detail-wrap"> 
        <div {...classes('wrapper')}>
          {header()}
          <div {...classes('body')}>  
            { 
                showFrom && (
                  formSchema && 
                <Form schema={formSchema} formData={formData} onChange={onFormChange} onSubmit={onFormSubmit} {...classes('section')}/>
              )
            }   
          </div>
          <div {...classes('addInputs')}>            
            
            <div {...classes('footer')}>
              <div {...classes('item')} onClick={()=>addSource()}>
                <img src={PluseIcon} /><p>Add Inputs</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        [...Array(target)].map((el, index) => 
          <Handler index={index} type={"target"} position={"left"} id={`target_${data.id}_${index}`} key={index} edge={target} className={"input"}/>
        //<Handle type="target" position="left" className="input" id={`target_${data.id}_${index}`} key={`target_${data.id}_${index}`} style={{ top:  getPosition(index,target)}}/>
        )
      }
      

      {
        [...Array(source)].map((el, index) => 
          <Handler index={index} type={"source"} position={"right"} id={`source_${data.id}_${index}`} key={index} edge={source} className={"output"}/>
        //<Handle type="source" position="right" className="output" id={`source_${data.id}_${index}`} key={`source_${data.id}_${index}`} style={{ top:  getPosition(index,source)}}/>
        )
      }
    </div>     
    </>
  );
});
// Set display name to be used in React Dev Tools
ConfigurationNode.displayName = "ConfigurationNode";
// PropTypes validation
ConfigurationNode.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    configurations: PropTypes.shape({
      formData: PropTypes.object,
      schema: PropTypes.object,
      Handle: PropTypes.shape({
        target: PropTypes.number,
        source: PropTypes.number,
      }),
      appendData: PropTypes.arrayOf(
        PropTypes.shape({
          overwrite: PropTypes.string,
          overwriteTo: PropTypes.string,
          dataType: PropTypes.string,
        })
      ),
      icon: PropTypes.string,
    }),
  }).isRequired,
};

export default ConfigurationNode;
