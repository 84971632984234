import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "antd/lib/button";
import FlowsService from "./../service/flowsService";
import Loading from "./../components/Loading";
import Layout from "antd/lib/layout";
import { Header } from "antd/lib/layout/layout";
import { Settings } from "./Settings";
import PropType from "prop-types";

const IVRFlows = (props) => {
  const { tenantName } = useParams();
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleString("en-US", {
      
      ...options,
    });
    return formattedDate;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedFlows = await FlowsService.getAllFlows(null, null, tenantName);
        setFlows(fetchedFlows);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching flows:", error);
      }
    };

    fetchData();
  }, [tenantName]);

  return (
    <div style={{ width: "100vw", backgroundColor: "#E9E6FF", margin:0}}>
      <Layout theme="light">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Settings toggle={toggle} collapsed={collapsed} signOut={props.signOut} />
        </Header>
      </Layout>
      <div style={styles.container}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <h2 style={{marginTop: '10px'}}>IVR Flows - {tenantName}</h2>
            <Button style={styles.button_header}>
                <p style={styles.text}>
                  Flow Name
                </p>
                <p style={styles.text}>versions</p>
                <p style={styles.text}>createdDate</p>
              </Button>
            {flows.map((flow) => (
              <Button style={styles.button}  
              onClick={() => navigate(`flow/${flow.id}`, { replace: true })} key={flow.id}>
                <p style={styles.text}>
                  {flow.name}
                </p>
                <p style={styles.text}>{flow.version}</p>
                <p style={styles.text}>{formatDate(flow.createdAt)}</p>
              </Button>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#E9E6FF",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    paddingBlock: "20px",
  },
  button: {
    position: "relative",
    display : "flex",
    color: '#573BFF',
    width: 'auto',
    borderColor: '#573BFF',
    paddingInline: '10px',
    marginInline: 'auto', 
    marginBlock: '5px',
    paddingBlock: '0px',
    height: 'auto',
    borderRadius: '5px'
  },
  button_header: {
    position: "relative",
    display : "flex",
    color: '#00000',fontWeight: 'bold',
    width: 'auto',
    backgroundColor:"#8A7AFF",
    borderColor: '#8A7AFF',
    paddingInline: '10px',
    paddingBlock: '10px',
    marginInline: 'auto', 
    marginBlock: '5px',
    height: 'auto'
  },
  text: {
    marginInline:"100px",
    width:'200px'


  }
};

IVRFlows.propTypes = {
  signOut: PropType.func.isRequired,
};

export default IVRFlows;
