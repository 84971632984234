import { Amplify, Auth } from 'aws-amplify';
import awsExports from './../../aws-exports';
import { CookieStorage } from 'amazon-cognito-identity-js';

Amplify.configure(Object.assign({
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  graphql_headers: async () => ({
    'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
  }),
  storage: new CookieStorage({
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    secure: false
  })
}, awsExports));

const decodeToken = async () => {
  try {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return JSON.parse(atob(jwtToken.split('.')[1])); // Decoded base64 and parses JSON
  } catch (error) {
    console.error('Error decoding token:', error);
    throw error;
  }
};

export { decodeToken };
