// import HomeOutlined from "@ant-design/icons/lib/icons/HomeOutlined";
// import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
// import RightCircleOutlined from "@ant-design/icons/lib/icons/RightCircleOutlined";
// import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import Select  from "antd/lib/select";
import Space from "antd/lib/space";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; //removed useNavigate because it was not used
import Typography from "antd/lib/typography";
import { useSelector, useDispatch } from "react-redux";
import {onVersionChanged} from "../redux/features/nodeConfigSlice";

const { Title } = Typography;
const { Option } = Select;

const ToolsKit = React.lazy(() =>  import('../../app/components/Nodes/toolsKit').then((module) => ({ default: module.ToolsKit })));
const LeftSider = ()=> {
  //commented below because it was not used
  //const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch(); 

  const nodeConfig = useSelector((state)=>state.nodeConfig);

  const [/*showMenu*/,setShowMenu] = useState(location.pathname === '/console');
  const [value,setValue] = useState(0);

  useEffect(() => {
    setShowMenu(location.pathname === '/console' || location.pathname === '/console/flows' || location.pathname === '/console/flow/new');
  }, [location]);
  
  useEffect(() => {
    if(nodeConfig.flowDetails?.version){
      setValue(nodeConfig.flowDetails?.version);
    }
  }, [nodeConfig.flowDetails?.version]);


  const handleChange = (value) => {
    setValue(value);
    dispatch(onVersionChanged(value));
  };

  return (
    <>
    <Title className="version" level={3}>{nodeConfig.flowDetails.flowName}</Title>
    <Space className="version-dropdown">    
      Version:       
      <Select defaultValue={0} style={{ width: 120 }} value={value} onChange={handleChange}>
        {nodeConfig.flowDetails.versions?.map((o)=><Option key={o.version} value={o.version}>{o.version}</Option>)} {/*added key prop */}       
      </Select>
    </Space>
    <ToolsKit />
    </>
    
  );
}
export default LeftSider;
